import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IFeatureDto } from '../dtos/feature-dto';
import { CasinoFeaturesService } from '../services/czam-api/features.service';

/**
 * Directive that shows or hides an element based on whether a list of features are active.
 *
 * Usage:
 *
 *
 *    In your component's template, use the directive to show or hide an element based on the active features.
 *    The 'isFeatureActive' input property takes in a string of feature names separated by '|'.
 *
 * Example:
 *
 *   <div *isFeatureActive="'feature-1|feature-2'">
 *     This content will only be shown if both 'feature-1' and 'feature-2' features are available and active.
 *   </div>
 *
 * Note: This directive uses the 'FeaturesService' to retrieve the available features. If 'FeaturesService.availableFeaturesAsync()'
 * returns an Observable, the directive will use RxJS operators to handle the asynchronous data.
 */

@Directive({
  selector: '[isFeatureActive]'
})
export class IsFeatureActiveDirective implements OnInit, OnDestroy {
  private _features: string[] = [];

  @Input()
  set isFeatureActive(features: string | null) {
    if (features) {
      this._features = features.split('|');
    }
  }

  private isVisible: boolean = false;
  private subscription?: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featuresService: CasinoFeaturesService
  ) {
  }

  ngOnInit() {
    this.subscription = this.featuresService.inactiveFeaturesAsync().pipe(
      switchMap((inactiveFeatures: IFeatureDto[]) => {
        // If any of the requested features are inactive, immediately return false
        const hasInactiveFeature = inactiveFeatures.some(f => this._features.includes(f.name));
        if (hasInactiveFeature) {
          return of(false);
        }

        // Otherwise, check if all requested features are active
        return this.featuresService.availableFeaturesAsync().pipe(
          map((features: IFeatureDto[]) => {
            const activeFeatures = features.filter(f => this._features.includes(f.name) && f.isActive);
            return activeFeatures.length >= this._features.length;
          })
        );
      })
    ).subscribe((isFeatureActive: boolean) => {
      if (isFeatureActive) {
        // Avoid creating multiple instances of the component
        if (!this.isVisible) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.isVisible = true;
        }
      } else {
        this.viewContainer.clear();
        this.isVisible = false;
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription?.unsubscribe();
  }

}
