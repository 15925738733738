<div class="picture-container"
  [ngClass]="{'border-green' : currentStep == CameraCaptureSteps.Preview && !isSpinnerLoaderOn}">
  <div class="sub-container-content">
    <h3 [ngClass]="{'w-full': !prefix}">
      <span class="step-number" *ngIf="currentStep != CameraCaptureSteps.Preview && prefix != null">{{ prefix }}</span>
      <span *ngIf="currentStep == CameraCaptureSteps.Preview" class="material-icons green">task_alt</span>
      <span class="title">{{ blockTitle | translate }}</span>
      <span class="close-icon"><mat-icon *ngIf="displayClose" (click)="close()">close</mat-icon></span>
    </h3>
    <p class="sub-title" *ngIf="currentStep == CameraCaptureSteps.Starting"
      [innerHTML]="'CAMERA_CAPTURE.NOTICE_1' | translate"></p>
    <p class="sub-title" *ngIf="currentStep == CameraCaptureSteps.Scanning">{{ 'CAMERA_CAPTURE.NOTICE_2' | translate }}
    </p>
    <p class="sub-title" *ngIf="currentStep == CameraCaptureSteps.Preview">{{ 'CAMERA_CAPTURE.NOTICE_3' | translate }}
    </p>

    <div *ngIf="isSpinnerLoaderOn" class="loader-container">
      <mat-spinner diameter="100" strokeWidth="5"></mat-spinner>
    </div>

    <div *ngIf="currentStep == CameraCaptureSteps.Starting && !isSpinnerLoaderOn" class="img-container  bg-stars">
      <div class="bg-webcam"></div>
    </div>
    <div #videoContainer class="video-container"
      [ngClass]="{'display-none' : currentStep != CameraCaptureSteps.Scanning || isSpinnerLoaderOn}">
      <video #video id="video" autoplay playsinline></video>
    </div>

    <div class="preview-container" *ngIf="currentStep == CameraCaptureSteps.Cropping && !isSpinnerLoaderOn">
      <div *ngIf="stringUrlImage">
        <image-cropper [imageURL]="stringUrlImage" [maintainAspectRatio]="true" [aspectRatio]="3 / 4"
          [disabled]="!isCropActivated" format="jpeg" [hideResizeSquares]="false"
          (imageCropped)="croppedImage($event)"></image-cropper>
      </div>
    </div>

    <div class="preview-container" *ngIf="currentStep == CameraCaptureSteps.Preview && !isSpinnerLoaderOn">
      <img [src]="based64File" />
    </div>

    <div class="buttons-container" *ngIf="!isSpinnerLoaderOn">
      <button *ngIf="currentStep == CameraCaptureSteps.Starting" mat-button class="cta-primary py-80"
        (click)="loadCamera()">
        {{ 'CAMERA_CAPTURE.BUTTON_WEBCAM' | translate }}
      </button>
      <button *ngIf="currentStep == CameraCaptureSteps.Scanning" mat-button class="cta-primary py-80"
        (click)="capture()">
        {{ 'CAMERA_CAPTURE.BUTTON_TAKE_PICTURE' | translate }}
      </button>
      <div class="double-buttons" *ngIf="currentStep == CameraCaptureSteps.Cropping">
        <button mat-button class="cta-secondary py-80" (click)="restart()">
          {{ 'CAMERA_CAPTURE.BUTTON_RETAKE_PICTURE' | translate }}
        </button>
        <button mat-flat-button class="cta-primary py-80"
          (click)="!isCropActivated ? isCropActivated = true : currentStep = CameraCaptureSteps.Preview">
          <mat-icon *ngIf="isLoading">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>
          <span *ngIf="!isCropActivated">{{ 'CAMERA_CAPTURE.BUTTON_RESIZE_PICTURE' | translate }}</span>
          <span *ngIf="isCropActivated">{{ 'CAMERA_CAPTURE.BUTTON_RESIZE_CONFIRM' | translate }}</span>
        </button>
      </div>
      <div class="double-buttons" *ngIf="currentStep == CameraCaptureSteps.Preview">
        <button mat-button class="cta-secondary py-80" (click)="restart()" [disabled]="isLoading">
          {{ 'CAMERA_CAPTURE.BUTTON_RETAKE_PICTURE' | translate }}
        </button>
        <button mat-flat-button class="cta-primary py-80" (click)="emitBase64File()" [disabled]="isLoading">
          <mat-icon *ngIf="isLoading">
            <mat-spinner diameter="20" color="accent"></mat-spinner>
          </mat-icon>
          <span><span>{{ !isLoading ? ('CAMERA_CAPTURE.BUTTON_CONFIRM_PICTURE' | translate) :
              ('CAMERA_CAPTURE.BUTTON_CREATE_ACCOUNT' | translate) }}</span></span>
        </button>
      </div>
    </div>
  </div>
</div>