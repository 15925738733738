<div class="user-card" *ngIf="!clientError.isError && model" [ngClass]="!isLoading ? clientCardBorder : ''">
  <!-- @fadeScaleCientCard -->
  <!-- *ngIf="contentChange" -->

  <div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div class="card-content" *ngIf="!isLoading && (!hasINJHomonymsToCheck || saleState)">

    <div class="client-fullscreen">

      <div class="client-info">

        <!-- CLIENT INFO -->

        <div class="top">
          <div class="left">

            <!-- IMAGE -->
            <div class="card-content-img-infos" [style.height]="isZooming?'500px':'100%'">
              <lib-image-with-zoom class="client-image" [imageUrl]="model.clientImageUrl" [relativeZoom]="true"
                                   [backdropColor]="'#323232'" (onZoom)="isZooming = $event"
                                   (onUpload)="upload($event)">
              </lib-image-with-zoom>
            </div>

            <!-- Card type -->
            <div class="card-type">
              <span class="badge-info" *ngIf="client?.rfid">
                <img src="assets/lib-common/icons/rfid-icon.svg" [matTooltip]="'TOOLTIPS.CVIP' | translate">
              </span>
              <span class="badge-info" *ngIf="client?.isBPlay">
                <img src="assets/lib-common/icons/b-play-logo.svg" [matTooltip]="'TOOLTIPS.BPLAY' | translate">
              </span>
            </div>

          </div>

          <div class="right">

            <label class="label-attribute">Nom</label>
            <p>
              <label class="user-info uppercase">
                {{ (model.preferredLastName ? model.preferredLastName : model.identity.birthName) | uppercase }}
                <span class="subname" *ngIf="model.hasDifferentBirthName">
                  <label>{{ 'VDI.CLIENT_CARD.BORN' | translate }}&nbsp;{{
                      model.identity.birthName | uppercase
                    }}</label>
                </span>
              </label>
            </p>

            <label class="label-attribute">{{ 'VDI.CLIENT_CARD.FIRSTNAMES' | translate }}</label>
            <p>
              <label class="user-info">
                {{ (model.preferredFirstName ? model.preferredFirstName : model.identity.firstNames) | capitalize }}
                <span *ngIf="model.hasDifferentFirstNames">né-e&nbsp;{{ model.identity.firstNames | capitalize }}</span>
              </label>
            </p>

            <label class="label-attribute">{{ 'VDI.CLIENT_CARD.BIRTHDATE' | translate }}</label>
            <p class="user-info display-flex">
              <span class="material-icons birthday-icon gold">cake</span>
              <span [ngClass]="{'salmon': model.isUnderage }" style="padding-top: 5px">{{
                  model.identity.dateOfBirth|
                    date:'dd/MM/yyyy'
                }}</span>
            </p>

            <label class="label-attribute">{{ 'VDI.CLIENT_CARD.CITY_OF_BIRTH' | translate }}</label>
            <p class="user-info">{{ model.identity.placeOfBirth ? model.identity.placeOfBirth : '-' }}</p>

            <label class="label-attribute">{{ 'VDI.CLIENT_CARD.COUNTRY_OF_BIRTH' | translate }}</label>
            <p class="user-info uppercase">{{ model.identity.countryOfBirth ? model.identity.countryOfBirth : '-' }}
            </p>
          </div>
        </div>

        <div class="bottom" *ngIf="model.ciamId">

          <div class="left fx-jc-center" [ngClass]="{'center-content-xy':model.fidelityLoading}">
            <!-- LOYALTY INFO -->
            <mat-icon class="auto-size" *ngIf="model.fidelityLoading">
              <mat-spinner diameter="40"></mat-spinner>
            </mat-icon>
            <lib-loyalty-info *ngIf="!model.fidelityLoading" [level]="model.fidelityLevel"
                              [clientState]="model.state"></lib-loyalty-info>
          </div>

          <div class="right">
            <div class="pt-5">
              <label class="label-attribute">
                <span *ngIf="model.cardValidityStatus == CardValidityStatus.Expired">{{
                    'VDI.CLIENT_CARD.EXPIRED_CARD'
                      | translate
                  }}</span>
                <span
                  *ngIf="model.cardValidityStatus == CardValidityStatus.ExpiredSoon || model.cardValidityStatus == CardValidityStatus.Active">{{
                    'VDI.CLIENT_CARD.VALID_UNTIL' | translate
                  }}</span>
              </label>

              <p class="user-info salmon card-information-display-flex"
                 *ngIf="model.cardValidityStatus == CardValidityStatus.Inactive || model.cardState==CardState.Inactive">
                <span class="material-icons warning-icon salmon">warning</span>
                <span *ngIf="model.cardState==CardState.Inactive" class="padding-top-text">
                  {{ 'VDI.CLIENT_CARD.DISABLE_CARD' | translate }}
                </span>
                <span *ngIf="model.cardState!=CardState.Inactive &&
                  model.cardValidityStatus == CardValidityStatus.Inactive" class="padding-top-text">
                  {{ 'VDI.CLIENT_CARD.INACTIVE_CARD' | translate }}
                </span>
              </p>

              <p class="user-info salmon card-information-display-flex"
                 *ngIf="model.cardValidityStatus == CardValidityStatus.Expired">
                <span class="material-icons warning-icon salmon">warning</span>
                <span class="padding-top-text">{{ model.cardValidityEndDate | date:'dd/MM/yyyy' }}</span>
              </p>

              <p class="user-info card-information-display-flex"
                 *ngIf="model.cardValidityStatus == CardValidityStatus.ExpiredSoon">
                <span class="material-icons warning-icon salmon">warning</span>
                <span class="padding-top-text">{{ model.cardValidityEndDate | date:'dd/MM/yyyy' }}</span>
              </p>

              <p class="user-info card-information-display-flex"
                 *ngIf="model.cardValidityStatus == CardValidityStatus.Active">
                {{ model.cardValidityEndDate| date:'dd/MM/yyyy' }}
              </p>

              <p class="user-link" *ngIf="type == 'Client'">
                <span><a (click)="navigateToCustomerExtended()">{{
                    'VDI.CLIENT_CARD.SEE_FILE' |
                      translate
                  }}</a></span>
              </p>
            </div>

            <div class="pt-7">
              <label class="label-attribute">{{ 'VDI.CLIENT_CARD.HOME_CASINO' | translate }}</label>
              <p class="user-info">{{ model.affiliationCasinoCode ? model.affiliationCasinoCode : '-' }}</p>
            </div>
          </div>

        </div>

      </div>

      <!-- RESTRICTIONS -->
      <lib-client-restriction *ngIf="model.restrictions && !isZooming"  [restrictions]="model.restrictions">
      </lib-client-restriction>

      <!-- NOTIFICATIONS -->
      <div class="notifications-container" *ngIf="(!model.fidelityLoading && checkInSearchOrigin == CheckInSearchOrigin.CheckIn &&
              (model.isClientStateValidationInProgress || model.hasDisplayNotification))">
        <p class="notification-title">
          <mat-icon class="gold">notifications</mat-icon>
          {{ 'VDI.CLIENT_CARD.NOTIFICATIONS' | translate }}
        </p>
        <ng-container *ngFor="let notification of model.notifications">
          <lib-notifications [notification]="notification">
          </lib-notifications>
        </ng-container>
      </div>

      <div class="client-action-notifications-container">
        <ng-container *ngFor="let clientAction of model.clientActionList$ | async">

          <span [ngSwitch]="clientAction.actionType">
            <lib-signature-notification *ngSwitchCase="ActionType.Signature" [ciamId]="model.ciamId"
                                        (signatureComplete)="signatureComplete(clientAction)"></lib-signature-notification>

            <lib-message-action *ngSwitchDefault [clientAction]="clientAction"
                                (actionComplete)="actionComplete(clientAction)">
            </lib-message-action>
          </span>


        </ng-container>
      </div>


      <!-- REDO SIGNATURE NOTIFICATION -->
      <!-- <div class="notifications-container">
        <p class="notification-title"><mat-icon class="gold">notifications</mat-icon>{{ 'VDI.CLIENT_CARD.NOTIFICATIONS' | translate }}</p>
        <app-signature-notification [client]="model"></app-signature-notification>
      </div> -->


      <!-- KYC CLIENT VALIDATION / INVALID ADDRESS / INACTIVE CARD -->
      <lib-client-to-validate-notification (onNewClientState)="updateClientState($event)" [client]="model"
                                           *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn && (model.isClientStateValidationInProgress)">
      </lib-client-to-validate-notification>

      <!-- MISSING FIELDS -->
      <lib-client-missing-fields *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn" [client]="model"
                                 (update)="onMissingFieldUpdated($event)">
      </lib-client-missing-fields>

    </div>

    <!-- ACTION BUTTONS -->
    <div class="card-actions" *ngIf="(!model.fidelityLoading || !allowSwiftSale); else spinner">
      <button class="cta-primary" mat-flat-button *ngIf="!model.ciamId
          && model.silentNotificationType == 'Unknown'
          && model.restrictedEntryType == 'Unknown'
          && checkInSearchOrigin == CheckInSearchOrigin.ClientCreation" (click)="createClientDraft()">
        <mat-icon prefix class="material-icons">add_circle_outline</mat-icon>
        {{ 'VDI.CLIENT_CARD.CREATE_ACCOUNT' | translate }}
      </button>

      <button class="cta-primary" mat-flat-button *ngIf="checkInSearchOrigin == CheckInSearchOrigin.ANPR &&
        model.restrictedEntryType != RestrictedEntryType.Gelav && !model.isUnderage &&
         model.restrictedEntryType != RestrictedEntryType.Inj && model.restrictedEntryType != RestrictedEntryType.Im &&
          model.restrictedEntryType != RestrictedEntryType.Anpr " (click)="createAnpr()">
        <mat-icon prefix class="material-icons">add_circle_outline</mat-icon>
        {{ 'VDI.CLIENT_CARD.CREATE_ANPR' | translate }}
      </button>


      <!-- BUTTON for offers -->
      <ng-container *isFeatureActive="'GUEST_REGARDS'">
        <button class="cta-secondary" mat-flat-button (click)="goToGuestRegards()" *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn &&
             !model.isUnderage &&
             model.ciamId &&
             model.state == ClientState.Active &&
             model.restrictedEntryType != RestrictedEntryType.Im &&
             model.restrictedEntryType != RestrictedEntryType.Anpr &&
             model.silentNotificationType != SilentNotificationType.Gelav &&
             model.silentNotificationType != SilentNotificationType.Video &&
             model.silentNotificationType != SilentNotificationType.Direction &&
             model.silentNotificationType != SilentNotificationType.CJ &&
             model.silentNotificationType != SilentNotificationType.Mcd &&
             model.silentNotificationType != SilentNotificationType.Prefecture &&
             model.silentNotificationType != SilentNotificationType.Police &&
             model.silentNotificationType != SilentNotificationType.CJR">
          <mat-icon prefix class="material-icons no-display-low-res">auto_awesome</mat-icon>
          {{ 'VDI.CLIENT_CARD.OFFERS_BUTTON' | translate }}
        </button>
      </ng-container>


      <button class="cta-secondary" mat-flat-button (click)="saveEntry(false)"
        *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn && !model.ciamId && allowSwiftSale"
        [disabled]="savingEntry
            || (model.topRestriction && !model.topRestriction.canEnter)
            || model.type == CheckInItemType.Client && model.state != ClientState.Active
            || model.cardValidityStatus == CardValidityStatus.Inactive
            || model.cardValidityStatus == CardValidityStatus.Expired
            || (!model.addressPostalCode || !model.addressCountry) && model.type == CheckInItemType.Client
            || model.qrCodeExpired || model.silentNotificationType == SilentNotificationType.Gelav
            || (model.type == CheckInItemType.Client && !model.identityDocumentStatus?.exists && !allowEntryForClientWhitoutIdendityDocument)">
        {{ 'VDI.CLIENT_CARD.ALREADY_PAID' | translate }}
      </button>


      <!-- BUTTON for DeniedEntries (GELAV only for now) -->
      <button
        *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn && model.silentNotificationType == SilentNotificationType.Gelav"
        mat-flat-button class="cta-primary"
        [ngClass]="{'denied-entry': model.silentNotificationType == SilentNotificationType.Gelav}"
        (click)="saveDeniedEntry()" [disabled]="savingEntry">
        <mat-icon *ngIf="savingEntry">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>
        <mat-icon *ngIf="!savingEntry" prefix class="material-icons">highlight_off</mat-icon>
        {{
          'VDI.CLIENT_CARD.DENIED_ENTRY' | translate
        }}
      </button>
      <!-- BUTTON when entering casino (except for GELAV) -->
      <button *ngIf="checkInSearchOrigin == CheckInSearchOrigin.CheckIn  &&
        (model.silentNotificationType == null || model.silentNotificationType != SilentNotificationType.Gelav)
         &&  ((allowSwiftSale && (saleState == 'confirmed' || saleState == 'paused') ||
         this.model?.fidelityLevel == FidelityLevel.Gold || this.model?.fidelityLevel == FidelityLevel.Black || this.model?.fidelityLevel == FidelityLevel.Ultimate || !allowSwiftSale)
           || model.isLastEntryDateTodaysCasinoDate)" mat-flat-button class="cta-primary"
        (click)="model.type != CheckInItemType.Restricted && saveEntry()"
        [disabled]="savingEntry
            || (model.topRestriction && !model.topRestriction.canEnter)
            || model.type == CheckInItemType.Client && model.state != ClientState.Active
            || model.cardValidityStatus == CardValidityStatus.Inactive
            || model.cardValidityStatus == CardValidityStatus.Expired
            || (!model.addressPostalCode || !model.addressCountry) && model.type == CheckInItemType.Client
            || model.qrCodeExpired
            || (model.type == CheckInItemType.Client && !model.identityDocumentStatus?.exists && !allowEntryForClientWhitoutIdendityDocument)">
        <mat-icon *ngIf="savingEntry">
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        </mat-icon>

        <div *ngIf="!savingEntry">
          <mat-icon
            *ngIf="hasClientAlreadyEnteredCurrentCasinoToday || (!allowSwiftSale && !hasClientAlreadyEnteredCurrentCasinoToday)"
            prefix class="material-icons" svgIcon="valid_star"></mat-icon>
          <span *ngIf="hasClientAlreadyEnteredCurrentCasinoToday && allowSwiftSale">{{
              'VDI.CLIENT_CARD.ALREADY_CHECKED_IN'
                | translate
            }}</span>

          <mat-icon *ngIf="allowSwiftSale && !hasClientAlreadyEnteredCurrentCasinoToday">print</mat-icon>
          <span *ngIf="!allowSwiftSale || (allowSwiftSale && !hasClientAlreadyEnteredCurrentCasinoToday)">{{
              'VDI.CLIENT_CARD.ENTER_CASINO' | translate
            }}</span>


        </div>
      </button>

      <button *ngIf="!hasClientAlreadyEnteredCurrentCasinoToday && allowSwiftSale && saleState != 'confirmed' && saleState != 'paused' && this.model?.fidelityLevel != FidelityLevel.Gold &&
       this.model?.fidelityLevel != FidelityLevel.Black && this.model?.fidelityLevel != FidelityLevel.Ultimate && checkInSearchOrigin == CheckInSearchOrigin.CheckIn &&
      (model.silentNotificationType == null || model.silentNotificationType != SilentNotificationType.Gelav)"
        [disabled]="savingEntry
      || (model.topRestriction && !model.topRestriction.canEnter)
      || model.type == CheckInItemType.Client && model.state != ClientState.Active
      || model.cardValidityStatus == CardValidityStatus.Inactive
      || model.cardValidityStatus == CardValidityStatus.Expired
      || (!model.addressPostalCode || !model.addressCountry) && model.type == CheckInItemType.Client
      || model.qrCodeExpired
      || (model.type == CheckInItemType.Client && !model.identityDocumentStatus?.exists && !allowEntryForClientWhitoutIdendityDocument)"
              mat-flat-button class="cta-primary" (click)="goToSales()">
        <mat-icon prefix class="material-icons">euro_symbol</mat-icon>
        {{ 'VDI.CLIENT_CARD.SALES' | translate }}
      </button>
    </div>

  </div>
  <div class="card-content-check" *ngIf="!isLoading && (hasINJHomonymsToCheck && !saleState)">
    <div>
      <span [innerHtml]="'VDI.INJ_CONFIRMATION.VALIDATE_TEXT' | translate"></span>
    </div>
    <div>

      <button class="cta-primary" (click)="confirmNoHomonym()" mat-flat-button>
        {{ 'VDI.INJ_CONFIRMATION.VALIDATE_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="sync-button-container" *ngIf="model?.isV1Client && !isLoading">
  <button mat-flat-button aria-label="Sync issue button" (click)="syncClientCardData()">
    <span class="sync-notice">{{ 'VDI.CLIENT_CARD.SYNC_NOTICE' | translate }}</span>
    <mat-icon>sync</mat-icon>
  </button>
</div>

<!-- WRONG CLIENT CARD -->
<div *ngIf="clientError.isError" class="user-card card-broken border-loading">
  <span class="material-icons warning-icon salmon">error</span>
  <p class="center">
    {{ 'VDI.CLIENT_CARD.ERROR_TITLE' | translate }} <br>
    "{{ clientError.description }}"<br>
    {{ 'VDI.CLIENT_CARD.ERROR_CODE' | translate }}<br><br><strong>{{ clientError.code }}</strong>
  </p>
</div>


<ng-template #spinner>
  <div class="loading-spinner">
    <mat-spinner color="primary" strokeWidth="5" diameter="40"></mat-spinner>
  </div>
</ng-template>
