import { Observable, Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  HostListener,
  Optional,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout/logout-dialog.component';
import { ICasinoDto } from '../../dtos/casino-dto';
import { DowngradedModeService } from '../../services/czam-api/downgraded-mode.service';
import { CasinoService } from '../../services/casino.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UtilsService } from '../../services/utils.service';
import { NotificationCenterService } from '../../services/notification-center-api/notification-center.service';
import { ToolbarTitleService } from '../../services/toolbar-title.service';
import { SessionService } from "../../services/session-storage.service";
import { HotelService } from '../../services/hotel.service';
import { IHotel } from '../../services/hotel-unifieddata-api/dtos/hotel-dto';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @Input() theme: string = "basic";
  @Input() title: string = '';
  @Input() displayBackground: boolean | null = false;

  @Output() toggleSideNavEvent = new EventEmitter();

  public userName: string | null = "";
  public shortUserName: string = "";
  public casino?: Observable<ICasinoDto | null>;
  public hotel?: Observable<IHotel | null>;
  public showDowngradedNotice: boolean = false;
  public isSticky = false;
  public isMobile: boolean = false;
  public isLoading: boolean = false;
  public isShowingShortName: boolean = true;
  public isSelectingHotel: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    public downgradedService: DowngradedModeService,
    @Optional() public casinoService: CasinoService,
    @Optional() public hotelService: HotelService,
    public authService: AuthenticationService,
    private utils: UtilsService,
    private _notificationCenterService: NotificationCenterService,
    private toolbarTitleService: ToolbarTitleService,
    private cdr: ChangeDetectorRef
  ) { }

  get notificationCenterService(): NotificationCenterService {
    return this._notificationCenterService;
  }


  // Listen to user scroll to change css header after 100px top position
  @HostListener('window:scroll', [])
  checkScroll() {
    this.isSticky = window.scrollY >= 100
  }

  ngOnInit(): void {
    let subscription = this.toolbarTitleService.getTitle().subscribe(res => {
      if (res !== null) {
        this.title = res;
        this.cdr.detectChanges();
      }
    });
    this.subscriptions.push(subscription);

    this.isMobile = this.utils.isMobile();

    if (this.casinoService) {
      this.casino = this.casinoService.getCurrentCasino();
    }

    if (this.hotelService) {
      this.hotel = this.hotelService.getCurrentHotel();
    }

    subscription = this.authService.user.subscribe(res => {

      if (SessionService.get('loggedUserUid') == res?.uid) {
        // Need this because of multiple project to keep track of the user's choice
        this.isShowingShortName = SessionService.get('isShowingShortName');
      } else {
        SessionService.set('isShowingShortName', true);
        SessionService.set('loggedUserUid', res?.uid);
      }

      this.userName = res?.displayName ?? '';
      this.setShortUserName();
    });
    this.subscriptions.push(subscription);

    // show message to user when CZAM is back online
    subscription = this.downgradedService.$isDowngraded.subscribe({
      next: (isOffline: boolean) => {
        // CZAM is offline
        if (isOffline) {
          // only show if not already popped-in
          if (!this.showDowngradedNotice) this.showDowngradedNotice = true;
        }
        // CZAM is online
        else {
          // only close if not already closed
          if (this.showDowngradedNotice) this.showDowngradedNotice = false;
        }
      }
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openModal() {
    this.dialog.open(LogoutDialogComponent, {
      width: '500px',
      height: '330px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
    })
  }

  openSelectionDialog(type: string) {
    if (this.casinoService && type === 'casino') {
      this.casinoService.openSelectCasinoDialog().then();
    }
    if (this.hotelService && type === 'hotel') {
      this.isSelectingHotel = true;
      this.hotelService.openSelectHotelDialog().then(() =>
        this.isSelectingHotel = false
      );
    }
  }

  /**
   * Set the short name to display.
   * Take the 2 first letter of Firstname Surname
   */
  setShortUserName(): void {
    if (this.userName && this.userName.length > 1) {
      let splitName = this.userName.split(" ");
      this.shortUserName = splitName[0].charAt(0) + splitName[1].charAt(0)
    } else {
      this.shortUserName = this.userName ?? '';
    }
  }

  toggleSideNav(): void {
    this.toggleSideNavEvent.emit();
  }

  /**
   * Reload the page to the given path
   */
  navigateTo(path: string) {
    window.location.href = path;
  }

  changeDisplayShortNameStateAndSaveInSession() {
    this.isShowingShortName = !this.isShowingShortName;
    SessionService.set('isShowingShortName', this.isShowingShortName);
  }

  public goToNotificationCenter() {
    this.navigateTo("/notification-center/notifications");
  }
}
