<mat-toolbar class="main-menu" id="matToolbar" [ngClass]="{
  'visible-background': theme == 'toolbar-with-bakground',
  'sticky' : isSticky
}">

  <div class="header-left-hand">
    <span class="app-icon">
      <a href="/home/" class="app-logo cursor-pointer">
        <img src="assets/lib-common/images/barriere-logo-couleurs.png" alt="app-logo">
      </a>
      <button mat-icon-button (click)="toggleSideNav()" class="menu-icon">
        <mat-icon color="primary">menu</mat-icon>
      </button>
    </span>

    <span *ngIf="showDowngradedNotice" class="downgraded">
      <span class="material-icons">wifi_off</span>
      <span class="hide-mobile">{{ 'HEADER.DOWNGRADED' | translate }}</span>
    </span>
    <span class="app-title">
      <ngx-skeleton-loader *ngIf="title === ''" class="skeleton" appearance="line" animation="pulse"
        [theme]="{ minWidth: '200px', minHeight: '10px', height: '100%', 'border-radius': '15px', width: '100%', 'background-color': '#565656'}"></ngx-skeleton-loader>
      <span *ngIf="title !== ''">{{ title | slice:0:23 }}</span>
    </span>
  </div>

  <div class="notifications desktop-mr-20">
    <button *ngIf="isMobile && (casino | async)" mat-icon-button aria-label="Notifications" class="notification-button"
      (click)="goToNotificationCenter()">
      <mat-icon color="primary">notifications</mat-icon>
      <span *ngIf="(notificationCenterService.notificationUncheckedNumber$ | async) as notificationsCount"
        class="notification-badge">{{ notificationsCount }}</span>
    </button>
    <!-- added this to center the title if no casino -->
    <div *ngIf="isMobile && !(casino | async)" class="no-casino-notification"></div>
  </div>

  <div class="header-right-hand">
    <button *ngIf="userName" mat-icon-button class="person mr--10" aria-label="Profile"
      (click)="changeDisplayShortNameStateAndSaveInSession()">
      <mat-icon color="primary">person</mat-icon>
    </button>
    <button *ngIf="userName" mat-button class="fullname" disabled>
      <span *ngIf="!isShowingShortName">{{ userName }}</span>
      <span *ngIf="isShowingShortName">{{ shortUserName }}</span>
    </button>


    <mat-divider *ngIf="casinoService && authService.hasCasinoRole" [vertical]="true"
      class="divider-fullname-casino"></mat-divider>

    <button *ngIf="casinoService && (casino | async)" mat-button class="casino" (click)="openSelectionDialog('casino')"
      [disabled]="!(authService.isAdmin$|async)">
      <mat-icon color="primary">casino</mat-icon>
      <span *ngIf="casinoService">{{ (casino | async)?.nom }}</span>
    </button>

    <button *ngIf="casinoService && authService.hasCasinoRole && (casino | async) == null"
      (click)="openSelectionDialog('casino')" mat-button class="no-casino">
      <span class="material-icons">priority_high</span>
      Aucun Casino Affilié
    </button>

    <mat-divider *ngIf="hotelService" [vertical]="true" class="divider-fullname-hotel"></mat-divider>

    <button *ngIf="hotelService" mat-button class="hotel" (click)="openSelectionDialog('hotel')"
      [disabled]="!(authService.isAdmin$|async) || isSelectingHotel">
      <mat-icon color="primary" *ngIf="!isSelectingHotel">hotel</mat-icon>
      <span class="select-hotel-label" *ngIf="(hotel | async) as currentHotel; else noHotel" [ngClass]="{ 'is-selecting': isSelectingHotel }">
        <mat-spinner color="accent" *ngIf="isSelectingHotel" [diameter]="20"></mat-spinner>{{ currentHotel.name }}
      </span>
      <ng-template #noHotel>Sélectionnez votre hôtel</ng-template>
    </button>

    <mat-divider [vertical]="true" class="divider-logout"></mat-divider>

    <button id="log-out-button" mat-icon-button class="logout" (click)="openModal()" aria-label="Log out">
      <mat-icon color="primary">logout</mat-icon>
    </button>
  </div>
</mat-toolbar>
