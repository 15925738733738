import { Injectable, Optional } from '@angular/core';
import { Observable, combineLatest, map, of} from "rxjs";
import { CasinoService } from "./casino.service";
import { AuthenticationService } from "./authentication.service";
import { Role } from "../enums/role.enum";
import { PlaceRole, PlaceType } from "../models/casino-role.class";

@Injectable({
    providedIn: 'root'
})
export class RoleGuardCheckService {


    constructor(@Optional() private casinoService: CasinoService, private authenticationService: AuthenticationService) {
    }


    getRoleGuard(roles: Role[]): Observable<boolean> {
      let isCasinoGranted$ = of(false);
      if (this.casinoService) {
        isCasinoGranted$ = combineLatest([this.casinoService.currentCasino$, this.authenticationService.roles$]).pipe(
          map(([currentCasino, currentUserRoles]) => {

              if (null === roles) {
                  return false;
              }

              if (roles.length === 0) {
                  return true;
              }

              if (currentUserRoles === null || currentUserRoles.length == 0) {
                  return false;
              }

            let currentUserRolesFiltered: PlaceRole[] = currentUserRoles.filter(role => role.placeType == PlaceType.Casino && (role.code == currentCasino?.code || (role.code == "" || role.code == '*')));
            let matchingRoles = currentUserRolesFiltered.filter(casinoRoles => roles.find(role => role == casinoRoles.role));

              return (matchingRoles && matchingRoles.length > 0);
          })
        )
      }
      let isHotelGranted$ = this.authenticationService.roles$.pipe(
        map((currentUserRoles) => {
          if (null === roles) {
            return false;
          }

          if (roles.length === 0) {
              return true;
          }

          if (currentUserRoles === null || currentUserRoles.length == 0) {
              return false;
          }

        let currentUserRolesFiltered: PlaceRole[] = currentUserRoles.filter(role => role.placeType == PlaceType.Hotel && (role.code == "" || role.code == '*'));
        let matchingRoles = currentUserRolesFiltered.filter(hotelRoles => roles.find(role => role == hotelRoles.role));

          return (matchingRoles && matchingRoles.length > 0);
        })
      )

      return combineLatest([isCasinoGranted$, isHotelGranted$]).pipe(map(([isCasinoGranted, isHotelGranted]) => isCasinoGranted || isHotelGranted));
    }
}
