import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { IFeatureDto } from '../../dtos/feature-dto';
import { CrudService } from '../crud.service';
import { map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CasinoService } from '../casino.service';

@Injectable({
  providedIn: 'root'
})
export class CasinoFeaturesService extends CrudService<IFeatureDto> {

  availableFeatures: IFeatureDto[] | null = null;
  inactiveFeatures: IFeatureDto[] | null = null;
  lastSelectedCasinoCode: string | null = null;

  constructor(http: HttpClient, @Optional() private casinoService: CasinoService) {
    super(environment.czamApiUrl + '/features', http);
  }

  public availableFeaturesAsync(): Observable<IFeatureDto[]> {

    // No casino, no casino feature
    if (this.casinoService == null) {
      return of([]);
    }

    return this.casinoService.getCurrentCasino().pipe(
      switchMap((casino) => {
        if (this.lastSelectedCasinoCode == casino?.code && this.availableFeatures != null) {
          return of(this.availableFeatures);
        }
        return this.getFiltered({ "isActive": true, casinoCode: "*," + casino?.code }).pipe(tap(f => {
          this.lastSelectedCasinoCode = casino?.code ?? null;
          this.availableFeatures = f;
        }));
      }))
  }


  public inactiveFeaturesAsync(): Observable<IFeatureDto[]> {

    // No casino, no casino feature
    if (this.casinoService == null) {
      return of([]);
    }

    return this.casinoService.getCurrentCasino().pipe(
      switchMap((casino) => {
        if (this.lastSelectedCasinoCode == casino?.code && this.inactiveFeatures != null) {
          return of(this.inactiveFeatures);
        }
        return this.getFiltered({ "isActive": false, casinoCode: "*," + casino?.code }).pipe(tap(f => {
          this.lastSelectedCasinoCode = casino?.code ?? null;
          this.inactiveFeatures = f;
        }));
      }))
  }

  public isAvailable(name: string): Observable<boolean> {

    return this.inactiveFeaturesAsync().pipe(
      mergeMap((inactiveFeatures: IFeatureDto[]) => {
        const hasInactiveFeature = inactiveFeatures.some(f => f.name == name);
        if (hasInactiveFeature) {
          return of(false);
        }

        return this.availableFeaturesAsync().pipe(
          map((features: IFeatureDto[]) => {
            const activeFeatures = features.filter(f => f.name == name && f.isActive);
            return activeFeatures.length > 0;
          })
        );
      })
    );
  }
}


