export class PlaceRole {
    constructor(role: string, code: string, placeType: PlaceType) {
        this.role = role;
        this.code = code;
        this.placeType = placeType;
    }
    public role: string | null = null;
    public code: string | null = null;
    public placeType: PlaceType | null = null;
}

export enum PlaceType {
    Casino = 'casino',
    Hotel = 'hotel'
}