import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { IBaseDto } from "../dtos/base-dto";

export class CrudService<T extends IBaseDto> {

  private urlBase: string;


  constructor(urlBase: string, private http: HttpClient) {
    this.urlBase = urlBase;
  }


  protected convert(dto: any): T {
    return dto as T;
  }


  public get(): Observable<T[]> {
    const url = this.urlBase;
    return this.http.get<T[]>(url).pipe(map(result => result.map(dto => this.convert(dto))));
  };

  public getFiltered(filters: any): Observable<T[]> {

    const sortBy = "-CreationDate";
    let url = this.urlBase + `?sort=${sortBy}`;

    if (filters && Object.keys(filters).length) {
      for (const [key, value] of Object.entries(filters)) {
        if (value != null) {
          url += `&${key}=${value}`;
        }
      }
    }

    return this.http.get<T[]>(url).pipe(map(result => result.map(dto => this.convert(dto))));
  };



  public getPaginated(from: number, take: number, sortBy: string = '-CreationDate', filter: any = null): Observable<T[]> {
    let url = this.urlBase + `?start=${from}&limit=${take}&sort=${sortBy}`;
    if (filter && Object.keys(filter).length) {
      for (const [key, value] of Object.entries(filter)) {
        if (value) {
          url += `&${key}=${value}`;
        }
      }
    }
    return this.http.get<T[]>(url).pipe(map(result => result.map(dto => this.convert(dto))));
  }

  public getOneById(resourceId: number): Observable<T> {
    const url = this.urlBase + '/' + resourceId;
    return this.http.get<T>(url).pipe(map(dto => this.convert(dto)));
  }


  public put(dto: T): Observable<T> {
    const url = this.urlBase + '/' + dto.id;
    return this.http.put<T>(this.urlBase, dto);
  }

  public delete(id: number): Observable<any> {
    return this.http.delete(`${this.urlBase}/${id}`);
  }

}
