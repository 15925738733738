import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, Observable, of, tap } from 'rxjs';
import { IHotel } from './dtos/hotel-dto';
import { IHotelPACLevelDto } from './dtos/hotel-pac-level-dto';

@Injectable({
  providedIn: 'root'
})
export class HotelContentManagerService {
  private hotelList: IHotel[] = [];

  constructor(private http: HttpClient) {}

  getHotelList(): Observable<IHotel[]> {
    return this.http.get<IHotel[]>(`${environment.hotelUnifiedDataApiUrl}/Hotels`).pipe(
      tap((hotels: IHotel[]) => this.hotelList = hotels)
    );
  }

  getHotelByCode(hotelCode: string): Observable<IHotel | null> {
    if (this.hotelList.length === 0) {
      return this.getHotelList().pipe(
        map((hotels: IHotel[]) => {
          const hotel = hotels.find(hotel => hotel.restrictionCode === hotelCode);
          return hotel ? hotel : null;
        })
      );
    } else {
      const hotel = this.hotelList.find(hotel => hotel.restrictionCode === hotelCode);
      return hotel ? of(hotel) : of(null);
    }
  }

  getHotelName(hotelCode: string): Observable<string> {
    return this.getHotelByCode(hotelCode).pipe(
      map(hotel => hotel ? hotel.name : '')
    );
  }

  getPacLevel(hotelCode: string): Observable<IHotelPACLevelDto> {
    return this.http.get<IHotelPACLevelDto>(`${environment.hotelUnifiedDataApiUrl}/NiveauPac/${hotelCode}`);
  }

}
