import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IHotel } from '../../services/hotel-unifieddata-api/dtos/hotel-dto';

export interface ISelectHotelDialogData {
  hotelList: IHotel[],
  currentHotel: IHotel | null;
}

@Component({
  selector: 'app-select-hotel-dialog',
  templateUrl: './select-hotel-dialog.component.html',
  styleUrls: ['./select-hotel-dialog.component.scss']
})
export class SelectHotelDialogComponent implements OnInit {

  public hotels: IHotel[] = [];
  public value: IHotel | null = null;

  constructor(
    public modalDialogRef: MatDialogRef<SelectHotelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISelectHotelDialogData,
  ) {
  }

  async ngOnInit() {
    this.hotels = this.data.hotelList;
    if (this.data.currentHotel) {
      this.value = this.data.currentHotel;
    } else {
      this.value = this.data.hotelList.length > 0 ? this.data.hotelList[0] : null;
    }
  }

  async saveHotel() {
    this.modalDialogRef.close({
      hotelList: this.hotels,
      currentHotel: this.value
    });
  }
}
