import { Injectable, Optional } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { HotelService } from './hotel.service';
import { Role } from '../enums/role.enum';

export interface GaItem {
  item_id: string;
  item_name: string;
}

export interface GtmTag {
  app_user_id: string;
  event: string;
  app_name: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

export class GtmService {
  public appName: string | null = null;

  constructor(private gtmService: GoogleTagManagerService, protected authService: AuthenticationService, @Optional() protected hotelService: HotelService) { }

  protected async createGtmTag(event: string, data?: { [key: string]: any }): Promise<GtmTag> {
    if (!this.appName) {
      throw new Error('App name is not set');
    }
    const user = await firstValueFrom(this.authService.user);

    let hotel = null;
    if (this.hotelService) {
      hotel = await firstValueFrom(this.hotelService.getCurrentHotel());
    }

    const roles = await firstValueFrom(this.authService.roles$);

    let isUserBresa = false;
    if (roles && roles.length === 1 && roles[0].role === Role.CVH) {
      isUserBresa = true;
    }

    // Push to data layer
    this.gtmService.getDataLayer().push({
      app_name: this.appName,
      app_user_id: user ? user.uid : 'unauthenticated',
      hotel_name: hotel?.name || 'no-hotel',
      user_bresa: isUserBresa,
    });


    return {
      app_user_id: user ? user.uid : 'unauthenticated',
      event: event,
      app_name: this.appName,
      hotel_code: hotel?.restrictionCode || 'no-hotel',
      user_bresa: isUserBresa,
      ...data
    };
  }

  protected async sendTag(gtmTag: GtmTag) {
    this.gtmService.pushTag(gtmTag).catch(err => {
      console.error(err);
    });
  }

  public async sendPageViewTag(urlAfterRedirects: string) {
    const tag = await this.createGtmTag('page_view', { page_location: urlAfterRedirects });

    await this.sendTag(tag);
  }

  public async sendAppEvent(type: string) {
    const tag = await this.createGtmTag('app_event', { app_event_type: type });

    await this.sendTag(tag);
  }
}
