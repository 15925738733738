import { firstValueFrom, Observable, ReplaySubject, timeout } from 'rxjs';
import { Injectable } from '@angular/core';
import { ICasinoDto, ICasinoInfoDto } from '../dtos/casino-dto';
import {
  ISelectCasinoDialogData,
  SelectCasinoDialogComponent
} from '../components/select-casino-dialog/select-casino-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceManagerService } from './device-manager.service';
import { Settings } from '../models/settings.class';
import { AuthenticationService, LogOutReason } from './authentication.service';
import { PlaceRole, PlaceType } from '../models/casino-role.class';
import { InformDialogComponent } from '../components/inform-dialog/inform-dialog.component';
import { UtilsService } from "./utils.service";
import * as Sentry from "@sentry/browser";
import { SystemService } from "./czam-api/system.service";

@Injectable()
export class CasinoService {


  private currentCasinoCode: string | null = null;
  private _currentCasino$: ReplaySubject<ICasinoDto | null> = new ReplaySubject<ICasinoDto | null>(1);

  get currentCasino$(): ReplaySubject<ICasinoDto | null> {
    return this._currentCasino$;
  }

  /**
   * Constructor
   */
  constructor(
    public dialog: MatDialog,
    private deviceManagerService: DeviceManagerService,
    private authenticationService: AuthenticationService,
    private utils: UtilsService,
    private systemService: SystemService
  ) {

    this.authenticationService.roles$.subscribe(async (roles: PlaceRole[] | null) => {
      if (roles) {
        await this.onNewRoles(roles);
      }
    })

    this.authenticationService.isLoggedIn.subscribe(async (isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        this.onSignOut();
      }
    })
  }

  /**
   * When new roles are received we must check if it is compatible with the current selected casino
   * @param currentUserRoles
   * @returns
   */
  private async onNewRoles(currentUserRoles: PlaceRole[]) {

    // Do nothing if we do not have a casino role
    if (!this.authenticationService.hasCasinoRole) {
      this.currentCasino$.next(null);
      return;
    }

    // Select the current casino, from device manager, role or list
    let currentCasino = await this.loadOrSelectCurrentCasino();

    const roles = currentUserRoles
      .filter(role => role.placeType == PlaceType.Casino && (role.code == currentCasino?.code || role.role == 'ADMIN'));

    if (currentCasino == null || roles.length == 0) {

      const action = 'Ok';
      const icon = 'done';
      const message = 'Vous n\'avez pas de rôle défini dans le casino paramétré'

      let dialogRef = this.dialog.open(InformDialogComponent, {
        disableClose: true,
        backdropClass: 'czam-dialog',
        panelClass: 'czam-panel-inform-dialog',
        height: '350px',
        width: '400px',
        data: { message: message, title: 'Erreur', action: action, icon: icon }
      });
      dialogRef.afterClosed().subscribe(async () => {
        await this.authenticationService.logout(LogOutReason.MissingRole);
      });

      return;
    }
  }

  /**
   * Called on signout
   */
  private onSignOut() {
    this.setCurrentCasinoCode(null);
  }

  /**
   * Call this function to set the current casino
   * @param casino
   * @param callDeviceManager
   */
  setCurrentCasinoCode(casino: string | null, callDeviceManager: boolean = true) {
    this.currentCasinoCode = casino;

    // Send information in sentry context
    if (casino) {
      Sentry.setContext("casino", {
        code: this.currentCasinoCode,
      });
    }

    if (casino && callDeviceManager) {
      this.deviceManagerService.setCurrentCasinoCode(casino);
    }
    this._currentCasino$.next(this.getCasinoByCode(this.currentCasinoCode)!);
  }

  /**
   * Call this to select
   * @returns
   */
  private async loadOrSelectCurrentCasino(): Promise<ICasinoDto | null> {
    let settings = new Settings();

    try {
      settings = await firstValueFrom(this.deviceManagerService.getSetting().pipe(timeout(5000)));
    } catch (exception) {
      console.log("Error: Cannot get settings:", exception);
    }

    if (settings.currentCasinoCode) {
      this.setCurrentCasinoCode(settings.currentCasinoCode, false);
    } else {
      const casinoList = await this.getCasinoListForUser();
      if (casinoList.length == 1) {
        this.setCurrentCasinoCode(casinoList[0].code, false);
      } else {
        await this.openSelectCasinoDialog();
      }
    }

    return await firstValueFrom(this._currentCasino$);
  }

  /**
   * Opens the select casino dialog
   * @returns
   */
  public async openSelectCasinoDialog() {
    const casinoList = await this.getCasinoListForUser();
    let ref = this.dialog.open(SelectCasinoDialogComponent, {
      maxWidth: '700px',
      maxHeight: '500px',
      minWidth: '320px',
      minHeight: '450px',
      backdropClass: 'czam-dialog',
      panelClass: 'czam-panel',
      disableClose: true,
      data: {
        casinoList: casinoList,
        currentCasino: this.currentCasinoCode
      }
    });
    ref.afterClosed().subscribe({
      next: (data: ISelectCasinoDialogData) => {
        this.setCurrentCasinoCode(data.currentCasino ? data.currentCasino.code : null, true);
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

  isCurrentCasinoBPlay() {
    return localStorage.getItem("BPLAY_CASINO_TEST") == "false";
  }

  getCurrentCasino(): Observable<ICasinoDto | null> {
    return this._currentCasino$.asObservable();
  }

  getCasinoCodeSync(): string | null {
    return this.currentCasinoCode;
  }

  getCasinosForDevAndUat() {
    const casinos = [
      { nom: 'Votre casino', id: null, code: null },
      { nom: "Casino Dinard", id: "casino_dinard", code: "DIN" },
      { nom: "Le Club 104", id: "club_le_104_paris", code: "PRS" },
      { nom: "Lab", id: "lab", code: "LAB" },
      { nom: "Lab-sto", id: "sto", code: "STO" },
      { nom: "Casino Saint-Raphael", id: "casino_saint_raphael", code: "SRP" },
      { nom: "Casino Carry le Rouet", id: "casino_carry_le_rouet", code: "CRY" }
    ]
    return casinos;
  }

  getCasinoByCode(code: string | null) {
    return this.getAllCasinos().find(c => c.code == code);
  }

  getCasinosByCodes(codes: (string | null)[]) {
    return this.getAllCasinos().filter(c => codes.includes(c.code));
  }

  getCasinosForDevAndUatByCodes(codes: (string | null)[]) {
    return this.getCasinosForDevAndUat().filter(c => codes.includes(c.code));
  }

  getAllCasinos() {
    const casinos: ICasinoDto[] = [
      { nom: 'Votre casino', id: null, code: null },
      { nom: "Casino Benodet", id: "casino_benodet", code: "BDT" },
      { nom: "Casino Biarritz", id: "casino_biarritz", code: "BIA" },
      { nom: "Casino Blotzheim", id: "casino_blotzheim", code: "BLZ" },
      { nom: "Casino Bordeaux", id: "casino_bordeaux", code: "BDX" },
      { nom: "Casino Cap d'Agde", id: "casino_cap_d_agde", code: "AGD" },
      { nom: "Casino Carry le Rouet", id: "casino_carry_le_rouet", code: "CRY" },
      { nom: "Casino Cassis", id: "casino_cassis", code: "CIS" },
      { nom: "Casino Courrendlin", id: "-", code: "CRD" },
      { nom: "Casino La Croisette Cannes", id: "casino_la_croisette_cannes", code: "CAN" },
      { nom: "Casino Deauville", id: "casino_deauville", code: "DEA" },
      { nom: "Casino Dinard", id: "casino_dinard", code: "DIN" },
      { nom: "Casino Enghien-les-Bains", id: "casino_enghien_les_bains", code: "ENG" },
      { nom: "Casino Fribourg", id: "-", code: "FRB" },
      { nom: "Casino La Baule", id: "casino_la_baule", code: "LBA" },
      { nom: "Casino La Rochelle", id: "casino_la_rochelle", code: "LRO" },
      { nom: "Casino Lille", id: "casino_lille", code: "LIL" },
      { nom: "Casino Le Touquet", id: "casino_le_touquet", code: "LTQ" },
      { nom: "Casino Menton", id: "casino_menton", code: "MEN" },
      { nom: "Casino Ruhl Nice", id: "casino_le_ruhl_nice", code: "NCE" },
      { nom: "Casino Niederbronn", id: "casino_niederbronn", code: "NDR" },
      { nom: "Casino Ouistreham", id: "casino_ouistreham", code: "OUI" },
      { nom: "Casino Ribeauvillé", id: "casino_ribeauville", code: "RBV" },
      { nom: "Casino Royan", id: "casino_royan", code: "ROY" },
      { nom: "Casino Saint-Malo", id: "casino_saint_malo", code: "SMA" },
      { nom: "Casino Sainte-Maxime", id: "casino_sainte_maxime", code: "SMX" },
      { nom: "Casino Saint-Raphael", id: "casino_saint_raphael", code: "SRP" },
      { nom: "Casino Toulouse", id: "casino_toulouse", code: "TLS" },
      { nom: "Casino Trouville", id: "casino_trouville", code: "TRO" },
      { nom: "Le Club 104", id: "club_le_104_paris", code: "PRS" },
      { nom: "Lab", id: "lab", code: "LAB" },
      { nom: "Lab-sto", id: "sto", code: "STO" }
    ]
    return casinos;
  }

  async getCasinoListForUser() {
    let currentEnvironment = (await firstValueFrom(this.systemService.getParameters())).environment;

    if (currentEnvironment == 'Development' || currentEnvironment == 'Uat' || currentEnvironment == 'Local') {
      return this.getCasinosForDevAndUat();
    }
    else if (await firstValueFrom(this.authenticationService.isAdmin$)) {
      return this.getAllCasinos();
    }
    else {
      let casinoCodes = this.authenticationService.restrictedCasinoCodeListForCurrentUser;
      return this.getCasinosByCodes(casinoCodes ?? []);
    }
  }

  getCasinoInfo(): ICasinoInfoDto[] {
    return [{
      id: 'casino_sainte_maxime',
      nom: 'Sainte-Maxime',
      code: 'SMX',
      imgmobile: 'assets/lib-common/images/casinos/sainte-maxime.jpg',
      imgdesktop: 'assets/lib-common/images/casinos/sainte-maxime-desktop.jpg',
      // Page Informations pratiques
      address: '23 Av du Général de Gaulle',
      zip: '83120',
      city: 'Sainte-Maxime',
      phone: '+33 4 94 55 07 00',
      schedulecasino1: '<span>Du dimanche au jeudi</span><span>09h00 / 03h00</span>',
      schedulecasino2: '<span>Vendredi et samedi</span><span>09h00 / 04h00</span>',
      schedulecasino3: '<span>Les veilles de jours fériés</span><span>09h00 / 04h00</span>',
      scheduletable1: '<span>Tous les jours</span><span>20h30 / 02h30</span>',
      scheduletable2: '<span>Vendredi, samedi et veilles de jours fériés</span><span>20h30 / 03h30</span>',
      serviceAccessible: true,
      servicePayment: true,
      serviceOverage: true,
      serviceWifi: true,
      onsiteWinebar: 1,
      onsiteRestaurant: 1,
      onsiteMeetingroom: 2,
      // Page Restaurant & Bar
      restaurantname: 'Le Côté Plage',
      restaurantimg: 'assets/lib-common/images/casinos/restaurant.jpg',
      restaurantschedule1: '<span>Service midi</span><span>12h00 / 15h00</span>',
      restaurantschedule2: '<span>Service soir</span><span>19h30 / 22h00</span>',
      restaurantwarning: '<span>Restaurant fermé les <span class="white">dimanches soir</span> et <span class="white">lundis soir</span> (Novembre à Mars)</span>',
      restaurantmenulink: 'https://menuonline.fr/coteplagerestaurant/carte-restaurant-ete-2022',
      barname: 'Le Côté Bar',
      barimg: 'assets/lib-common/images/casinos/bar.jpg',
      barschedule1: '<span>La semaine</span><span>12h30 / 02h00</span>',
      barschedule2: '<span>Le week-end</span><span>12h30 / 03h00</span>',
      barmenulink: 'https://menuonline.fr/coteplagerestaurant/carte-bar'
    }]
  }
}
