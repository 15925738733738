import { NgModule } from '@angular/core';
import { CzamCommonComponent } from './czam-common.component';
import { BottomSheetComponent } from "./components/bottom-sheet/bottom-sheet-component";
import { SnackBarComponent } from "./components/snack-bar/snack-bar.component";
import { SelectCasinoDialogComponent } from "./components/select-casino-dialog/select-casino-dialog.component";
import { ButtonsDialogComponent } from './components/buttons-dialog/buttons-dialog.component';

import { ImageWithZoomComponent } from './components/image-with-zoom/image-with-zoom.component';
import { ImageWithRectoVersoComponent } from './components/image-with-recto-verso/image-with-recto-verso.component';
import { ClientToValidateNotificationComponent, DeleteClientModalComponent } from './components/client-to-validate-notification/client-to-validate-notification.component';
import { LoyaltyInfoComponent } from './components/loyalty-info/loyalty-info.component';
import { ClientRestrictionComponent } from './components/client-restriction/client-restriction.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SignatureNotificationComponent } from './components/signature-notification/signature-notification.component';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDateFormats, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { CommonModule, DatePipe, LowerCasePipe, registerLocaleData } from "@angular/common";
import { RouterModule } from '@angular/router';
import {
  PasswordForgottenDialogComponent
} from './components/password-forgotten-dialog/password-forgotten-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { LogoutDialogComponent } from './components/logout/logout-dialog.component';
import { InAppNotificationsComponent } from './components/in-app-notifications/in-app-notifications.component';
import {
  ConfirmationBottomSheetComponent
} from './components/confirmation-bottom-sheet/confirmation-bottom-sheet.component';
import { DowngradedDialogComponent } from './components/downgraded-dialog/downgraded-dialog.component';
import { IsFeatureActiveDirective } from './directives/is-feature-active.directive';
import localeFr from '@angular/common/locales/fr';
import { AddAuthInterceptorService } from './interceptors/add-auth-interceptor.service';
import { LogoutInterceptorService } from './interceptors/logout-interceptor.service';
import {
  REQUEST_DEFAULT_TIMEOUT,
  TimeoutRequestInterceptorService
} from './interceptors/timeout-request-interceptor.service';
import { WithCredentialInterceptorService } from './interceptors/with-credentials-interceptor.service';
import { FakeLoyaltyComponent } from './pages/examples/loyalty-examples/fake-loyalty-example/fake-loyalty-component';
import { InformDialogComponent } from './components/inform-dialog/inform-dialog.component';
import { SignPipe } from './pipes/sign.pipe';
import { CustomerProfileCardComponent } from './components/customer-profile-card/customer-profile-card.component';
import { IsGrantedDirective } from './directives/is-granted.directive';
import { ConfirmationMatDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CameraCaptureComponent } from './components/camera-capture/camera-capture.component';
import { ImageCropperModule } from "ngx-image-cropper";
import { MessageActionComponent } from './components/client-action/message-action.component';
import { VersionComponent } from './components/version/version.component';
import { MatSelectModule } from "@angular/material/select";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { ZoomPictureDialogComponent } from "./components/zoom-picture-dialog/zoom-picture-dialog.component";
import { ActionButtonsBottomSheetComponent } from './components/action-buttons-bottom-sheet/action-buttons-bottom-sheet-component';
import { TransformInputDirective } from './directives/transform-input.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SelectHotelDialogComponent } from "./components/select-hotel-dialog/select-hotel-dialog.component";
import { SplitPageComponent } from './pages/split-page/split-page.component';
import { ClientOperationStepsComponent } from "./components/client-operation-steps/client-operation-steps.component";
import { StepProcessComponent } from './pages/split-page/desktop/step-process/step-process.component';
import { SentryInterceptor } from './interceptors/sentry-interceptor.service';
import { ValidationProcessGenericComponent } from "./components/mobile/validation-process/validation-process-generic.component";
import { RestrictionsSelectorComponent } from './components/restrictions-selector/restrictions-selector.component';
import { CreateCheckStepsComponent } from "./components/create-check-steps/create-check-steps.component";
import { ClientCardComponent } from "./components/client-card/client-card.component";
import { ClientMissingFieldsComponent } from "./components/client-missing-fields/client-missing-fields.component";
import { CheckInSearchResultComponent } from "./components/check-in-search-result/check-in-search-result.component";
import { SortComponent } from "./components/sort/sort.component";
import { CheckInItemComponent } from "./components/check-in-search-result/components/check-in-item/check-in-item.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import {
  RestrictionInformationDialogComponent
} from "./components/restriction-information-dialog/restriction-information-dialog.component";
import { LoadingDialogComponent } from "./components/loading-dialog/loading-dialog.component";
import {
  FakeClientCardComponent
} from "./pages/examples/client-card-examples/fake-client-card/fake-client-card.component";
import { WrapperComponent } from "./pages/examples/wrapper/wrapper.component";
import { ClientCardExamplesComponent } from "./pages/examples/client-card-examples/client-card-examples.component";
import { HotelBookingComponent } from './components/booking/hotel-booking.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
  BrowserAnimationsModule,
  MatBottomSheetModule,
  MatSelectModule,
  MatTabsModule,
  MatTableModule,
  MatToolbarModule,
  MatAutocompleteModule,
  MatCardModule,
  MatButtonModule,
  MatExpansionModule,
  MatGridListModule,
  MatInputModule,
  MatIconModule,
  MatBadgeModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatListModule,
  MatChipsModule,
  MatCheckboxModule,
  MatRadioModule,
  MatStepperModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatMenuModule,
  MatRippleModule,
  MatSortModule,
  MatSidenavModule,
  MatSliderModule,
  MatMomentDateModule,
  ScrollingModule
];

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lib-common/i18n/', '.json');
}

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    CzamCommonComponent,
    BottomSheetComponent,
    SnackBarComponent,
    SelectCasinoDialogComponent,
    SelectHotelDialogComponent,
    ImageWithZoomComponent,
    ImageWithRectoVersoComponent,
    ClientToValidateNotificationComponent,
    LoyaltyInfoComponent,
    ClientRestrictionComponent,
    NotificationsComponent,
    DeleteClientModalComponent,
    FakeLoyaltyComponent,
    PasswordForgottenDialogComponent,
    AppHeaderComponent,
    LogoutDialogComponent,
    InAppNotificationsComponent,
    ConfirmationBottomSheetComponent,
    DowngradedDialogComponent,
    InformDialogComponent,
    IsFeatureActiveDirective,
    IsGrantedDirective,
    ButtonsDialogComponent,
    SignPipe,
    CustomerProfileCardComponent,
    ConfirmationMatDialogComponent,
    CameraCaptureComponent,
    SignatureNotificationComponent,
    MessageActionComponent,
    VersionComponent,
    OrderByPipe,
    SignaturePadComponent,
    ZoomPictureDialogComponent,
    ActionButtonsBottomSheetComponent,
    TransformInputDirective,
    CapitalizePipe,
    SplitPageComponent,
    ClientOperationStepsComponent,
    StepProcessComponent,
    ValidationProcessGenericComponent,
    RestrictionsSelectorComponent,
    CreateCheckStepsComponent,
    ClientCardComponent,
    ClientMissingFieldsComponent,
    CheckInSearchResultComponent,
    SortComponent,
    CheckInItemComponent,
    RestrictionInformationDialogComponent,
    LoadingDialogComponent,
    FakeClientCardComponent,
    WrapperComponent,
    ClientCardExamplesComponent,
    HotelBookingComponent
  ],
  imports: [
    ...modules,
    NgxSkeletonLoaderModule.forRoot({ animation: "pulse", theme: { 'background-color': '#323232' } }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ImageCropperModule
  ],
  exports: [
    ...modules,
    CzamCommonComponent,
    BottomSheetComponent,
    SnackBarComponent,
    SelectCasinoDialogComponent,
    SelectHotelDialogComponent,
    LoyaltyInfoComponent,
    ImageWithZoomComponent,
    ImageWithRectoVersoComponent,
    ClientRestrictionComponent,
    NotificationsComponent,
    ClientToValidateNotificationComponent,
    AppHeaderComponent,
    InAppNotificationsComponent,
    IsFeatureActiveDirective,
    IsGrantedDirective,
    ButtonsDialogComponent,
    CustomerProfileCardComponent,
    CameraCaptureComponent,
    SignatureNotificationComponent,
    MessageActionComponent,
    VersionComponent,
    OrderByPipe,
    SignaturePadComponent,
    ActionButtonsBottomSheetComponent,
    TransformInputDirective,
    CapitalizePipe,
    SplitPageComponent,
    ClientOperationStepsComponent,
    StepProcessComponent,
    ValidationProcessGenericComponent,
    RestrictionsSelectorComponent,
    CreateCheckStepsComponent,
    ClientCardComponent,
    ClientMissingFieldsComponent,
    CheckInSearchResultComponent,
    SortComponent,
    CheckInItemComponent,
    RestrictionInformationDialogComponent,
    LoadingDialogComponent,
    WrapperComponent,
    ClientCardExamplesComponent,
    FakeClientCardComponent,
    HotelBookingComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutRequestInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialInterceptorService,
      multi: true,
    },
    { provide: REQUEST_DEFAULT_TIMEOUT, useValue: 60000 },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    LowerCasePipe,
    DatePipe,
    SignPipe
  ]
})
export class CzamCommonModule {
}
